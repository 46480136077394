<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="offset-md-2 col-md-8 col-xs-12">
            <div class="card">
              <div class="card-body form-steps">
                <div class="text-center pt-4 pb-4 mb-1">
                  <h5>{{ $t("t-setup-wizard") }}</h5>
                </div>

                <div id="custom-progress-bar" class="progress-nav mb-4">
                  <div class="progress" style="height: 1px;">
                    <div class="progress-bar" :style="{ width: progressBarWidth }" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>

                  <ul class="nav nav-pills progress-bar-tab custom-nav" role="tablist">
                    <li class="nav-item" v-for="(step, index) in steps" :key="index" role="presentation">
                      <button class="nav-link rounded-pill" :class="{ active: currentStep === index + 1, done: currentStep > index + 1 }" @click="goToStep(index + 1)" type="button">{{ index + 1 }}</button>
                    </li>
                  </ul>
                </div>

                <div>
                  <step1 v-if="currentStep === 1" @exchangeSelected="handleExchangeSelected" @optionSelected="handleOptionSelected" @nextStep="goToStep"></step1>
                  <step2 v-if="currentStep === 2" @nextStep="nextStep" @prevStep="prevStep"></step2>
                  <step3 v-if="currentStep === 3" @nextStep="nextStep" @prevStep="prevStep"></step3>
                  <step4 v-if="currentStep === 4" @nextStep="nextStep" @prevStep="prevStep"></step4>
                </div>

                <div class="card-footer">
                  <div class="row">
                    <div class="col-6 text-start">
                      <button class="btn btn-success btn-md btn-label left ms-auto previestab" type="button" @click="prevStep" :disabled="currentStep === 1">
                        <div class="btn-content">
                          <i class="ri-arrow-left-line label-icon align-middle fs-16"></i>
                          {{ $t("t-back") }}
                        </div>
                      </button>
                    </div>
                    <div class="col-6 text-end">
                      <button class="btn btn-success btn-md btn-label right ms-auto nexttab" type="button" @click="handleNextStep" :disabled="currentStep === steps.length">
                        <div class="btn-content">
                          <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          {{ $t("t-continue") }}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import step1 from "@/pages/setup-wizard/step1";
import step2 from "@/pages/setup-wizard/step2";
import step3 from "@/pages/setup-wizard/step3";
import step4 from "@/pages/setup-wizard/step4";

export default {
  data() {
    return {
      currentStep: 1,
      selectedExchange: null,
      selectedOption: 'no',
      steps: [step1, step2, step3, step4]
    };
  },
  computed: {
    progressBarWidth() {
      return (this.currentStep - 1) / (this.steps.length - 1) * 100 + '%';
    }
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.steps.length) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    goToStep(step) {
      this.currentStep = step;
    },
    handleExchangeSelected(exchange) {
      this.selectedExchange = exchange;
      this.goToStep(3)
    },
    handleOptionSelected(option) {
      this.selectedOption = option;
    },
    handleNextStep() {
      if (this.currentStep === 1) {
        if (this.selectedOption === 'yes') {
          this.goToStep(3);
        } else {
          this.goToStep(2);
        }
      } else {
        this.nextStep();
      }
    }
  },
  components: {
    step1,
    step2,
    step3,
    step4
  }
};
</script>