<template>
  <div class="tab-pane fade show active" id="choose-exchange-step" role="tabpanel" aria-labelledby="choose-exchange-step-tab">
    <div>
      <div class="mb-4">
        <div>
          <h5 class="mb-1">{{ $t("t-do-you-have-exchange") }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="p-2">
            <a href="#" v-for="exchange in exchanges" :key="exchange.name" @click="selectExchange(exchange)">
              <img :src="exchange.image" class="m-3" height="30" :alt="exchange.name">
            </a>
          </span>
        </div>
        <div class="col-12 text-center mb-3 mt-4">
          <!-- Radio Buttons -->
          <b-button-group role="group" aria-label="Do you have exchange account?">
            <input type="radio" class="btn-check" name="have_exchange" id="have_exchange_yes" value="yes" autocomplete="off" @change="handleRadioChange('yes')">
            <label class="btn btn-outline-secondary" for="have_exchange_yes">{{ $t("t-yes") }}</label>

            <input type="radio" class="btn-check" name="have_exchange" id="have_exchange_no" value="no" autocomplete="off" @change="handleRadioChange('no')" checked>
            <label class="btn btn-outline-secondary" for="have_exchange_no">{{ $t("t-no") }}</label>
          </b-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exchanges: [
        { name: 'bitget', image: require('@/assets/images/integrations/bitget.png') },
        { name: 'binance', image: require('@/assets/images/integrations/binance.png') },
        { name: 'bybit', image: require('@/assets/images/integrations/bybit.png') },
        { name: 'kraken', image: require('@/assets/images/integrations/kraken.png') },
        { name: 'coinbase', image: require('@/assets/images/integrations/coinbase.png') },
        { name: 'kucoin', image: require('@/assets/images/integrations/kucoin.png') },
        { name: 'okx', image: require('@/assets/images/integrations/okx.png') }
      ],
      selectedOption: 'no'
    };
  },
  methods: {
    selectExchange(exchange) {
      this.$emit('exchangeSelected', exchange);
    },
    handleRadioChange(option) {
      this.selectedOption = option;
      this.$emit('optionSelected', option);
    }
  }
};
</script>