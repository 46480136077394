<template>
  <div>
    <div class="tab-pane" id="register-exchange-step" role="tabpanel" aria-labelledby="register-exchange-step-tab">
      <form>
        <div>
          <div class="mb-4">
            <div>
              <h5 class="mb-1">{{ $t("t-lets-create-an-exchange-account") }}</h5>
              <p class="text-muted">{{ $t("t-use-instruction-below") }}</p>

              <div class="col-12 mb-5">
                <span class="border border-2 me-4 p-2">
                  <a href="https://www.binance.com/en" target="_blank">
                    <img src="@/assets/images/integrations/binance.png" height="30">
                  </a>
                </span>
                <p class="text-muted">
                  <a href='https://www.binance.com/en/support/faq/how-to-register-on-binance-website-08f314ad219e45fc9b7a27b67801a5c4' target="_blank">
                    <i class="mdi mdi-file-document"></i>&nbsp;
                    <span>{{ $t("t-wizard-step2-how-to-register") }}</span>
                  </a>
                  <br>
                  <a href='https://www.binance.com/en/support/faq/how-to-complete-entity-verification-on-binance-a-step-by-step-guide-360015552032' target="_blank">
                    <i class="mdi mdi-file-document"></i>&nbsp;
                    <span>{{ $t("t-wizard-step2-how-to-complete-verification") }}</span>
                  </a>
                </p>
              </div>

              <!-- Repeat similar blocks for other exchanges -->

            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step2',
};
</script>

<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>