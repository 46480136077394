<template>
  <div class="tab-pane" id="add-api-key" role="tabpanel" aria-labelledby="add-api-key-tab">
    <form>
      <div>
        <div class="mb-4">
          <div>
            <h5 class="mb-1">{{ $t("t-securely-connect-your-account") }}</h5>
            <p class="text-muted">
              {{ $t("t-create-api-key-and-input") }}
            </p>
            <p class="text-muted">
              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на Binance?</span>
              </a>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.binance.com/ru/support/faq/%D0%BA%D0%B0%D0%BA-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%B2%D0%B0%D1%82%D1%8C-api-%D0%BA%D0%BB%D1%8E%D1%87%D0%B8-%D0%BD%D0%B0-binance-360002502072'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on binance?</span>
              </a>
              <br>

              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.bitget.com/api-doc/common/quick-start#:~:text=If%20you%20need%20to%20use,an%20API%20Key%20after%20login.'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на bitget?</span>
              </a>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.bitget.com/api-doc/common/quick-start#:~:text=If%20you%20need%20to%20use,an%20API%20Key%20after%20login.'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on bitget?</span>
              </a>
              <br>

              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.bybit.com/en/help-center/article/How-to-create-your-API-key'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на bybit?</span>
              </a>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.bybit.com/en/help-center/article/How-to-create-your-API-key'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on bybit?</span>
              </a>
              <br>

              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://support.kraken.com/hc/en-us/articles/360000919966-How-to-create-an-API-key'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на kraken?</span>
              </a>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://support.kraken.com/hc/en-us/articles/360000919966-How-to-create-an-API-key'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on kraken?</span>
              </a>
              <br>

              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://help.coinbase.com/en/exchange/managing-my-account/how-to-create-an-api-key'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на coinbase?</span>
              </a>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://help.coinbase.com/en/exchange/managing-my-account/how-to-create-an-api-key'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on coinbase?</span>
              </a>
              <br>

              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.kucoin.com/support/360015102174'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на kucoin?</span>
              </a>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.kucoin.com/support/360015102174'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on kucoin?</span>
              </a>
              <br>

              <a v-if="currentLocale() === 'ru'" target="_blank" href='https://www.okx.com/ru/web3/build/docs/waas/introduction-to-developer-portal-interface'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>Как создать апи ключи на okx?</span>
              </a>
              <a v-if="currentLocale() === 'en' || currentLocale() === 'pl'" target="_blank" href='https://www.okx.com/ru/web3/build/docs/waas/introduction-to-developer-portal-interface'>
                <i class="mdi mdi-file-document"></i>&nbsp;
                <span>How to create api key on okx?</span>
              </a>
              <br>
            </p>
          </div>
        </div>
        <div class="text-start">
          <add-api-key></add-api-key>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {localeComputed} from "@/state/helpers";
import addApiKey from "@/pages/api-key-form-modal";
import ExchangeConnection from "@/state/entities/exchangeConnection";

export default {
  data() {
    return {
    };
  },
  components: {
    ...localeComputed,
    addApiKey
  },
  methods: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    ExchangeConnection.api().get('/exchange-connection');
  }
};
</script>