<script>
import UserService from "@/services/UserService";
import LanguageSelector from "@/components/language-selector";

    export default {
        data(){
          return {
            form: {
              email: "",
              phone_number: "",
              phone_country_code: "",
              username: "",
              password: "",
              referral_code: "",
              utm_source: "",
              utm_medium: "",
              utm_campaign: "",
              utm_term: "",
              utm_content: ""
            },
            disabled_referral_input: false
          }
        },
      components: {
        LanguageSelector,
      },
      beforeMount() {
        this.form.referral_code = this.$route.query.code;

        if (! this.form.referral_code) {
          this.form.referral_code = this.getItemFromStorage('referral_code');
        }

        if (this.form.referral_code) {
          this.saveItemToStorage('referral_code', this.form.referral_code);
          this.disabled_referral_input = true;
        }

        let utm_source = this.getCookie('utm_source');
        let utm_medium = this.getCookie('utm_medium');
        let utm_campaign = this.getCookie('utm_campaign');
        let utm_term = this.getCookie('utm_term');
        let utm_content = this.getCookie('utm_content');

        let referral_code = this.getCookie('referral_code');

        if (utm_source) {
          this.form.utm_source = this.getCookie('utm_source');
        }
        if (utm_medium) {
          this.form.utm_medium = this.getCookie('utm_medium');
        }
        if (utm_campaign) {
          this.form.utm_campaign = this.getCookie('utm_campaign');
        }
        if (utm_term) {
          this.form.utm_term = this.getCookie('utm_term');
        }
        if (utm_content) {
          this.form.utm_content = this.getCookie('utm_content');
        }
        if (referral_code) {
          this.form.referral_code = this.getCookie('referral_code');
        }
      },
      mounted() {
        const input = document.getElementById("phone_number");
        //Австрия, Бельгия, Болгария, Венгрия, Германия, Греция, Дания, Ирландия, Испания, Италия, Кипр, Латвия
        //Литва, Люксембург, Мальта, Нидерланды, Польша, Португалия, Румыния, Словакия, Словения, Финляндия
        //Франция, Хорватия, Чехия, Швеция, Эстония
        window.intlTelInput(input, {
          initialCountry: "auto",
          geoIpLookup: function(success, failure) {
            fetch("https://ipinfo.io/?token=569f8621dbbca0")
                .then(res => res.json())
                .then(function(data) { success(data.country); })
                .catch(function() { failure(); });
          },
          onlyCountries: [
            "at", "be", "bg", "hu", "de", "gr", "dk", "ie", "es", "it", "cy", "lv",
            "lt", "lu", "mt", "nl", "pl", "pt", "ro", "sk", "si", "fi",
            "fr", "hr", "cz", "se", "ee"
          ],
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
        });
        input.addEventListener("countrychange", () => {
          let iti = window.intlTelInputGlobals.getInstance(input);
          let countryData = iti.getSelectedCountryData();
          this.form.phone_country_code = countryData.dialCode;
        });
        input.addEventListener("keyup", function() {
          let iti = window.intlTelInputGlobals.getInstance(input);

          if (iti.isValidNumber()) {
            // console.log('VALID');
            input.style = "border: 1px solid green";
          } else {
            // console.log('INVALID');
            input.style = "border: 1px solid red";
          }
        })
      },
      methods: {
        getCookie(cname) {
          let name = cname + "=";
          let decodedCookie = decodeURIComponent(document.cookie);
          let ca = decodedCookie.split(';');
          for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        },
        saveItemToStorage(key, state) {
          window.localStorage.setItem(key, (state));
        },
        getItemFromStorage(key) {
          return window.localStorage.getItem(key);
        },
        async submit() {
          const input = document.getElementById("phone_number");
          let iti = window.intlTelInputGlobals.getInstance(input);
          let countryData = iti.getSelectedCountryData();
          // console.log(countryData.dialCode);
          this.form.phone_country_code = countryData.dialCode;

            const response = await UserService.registration(this.form).catch((r) => {
              return r.response;
            })
            this.saveItemToStorage('verification-phone-user-id', response.data._id)
            console.log(response)
            if (response.status === 201) {
              this.$router.push("/phone-verification");
            }
        },
        async google() {
          location.href = process.env.VUE_APP_API_HOST + 'google';
        },
      },
    }

</script>
<style>
.iti {
  display: block !important;
}
</style>
<template>

    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>

            <div class="shape">

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
        </div>
        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-j2p.png" alt="" height="50">
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">
                            <div class="card-body p-4">
                              <div class="text-end float-end">
                                <language-selector></language-selector>
                              </div>
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">{{ $t("t-create-new-account") }}</h5>
                                </div>
                                <div class="p-2 mt-4">
                                    <form class="needs-validation" v-on:submit.prevent="submit">

                                        <div class="mb-3">
                                            <label for="useremail" class="form-label">{{ $t("t-contact-email") }} <span
                                                    class="text-danger">*</span></label>
                                            <input type="email" class="form-control" id="useremail"
                                                   v-model="form.email"
                                                :placeholder='$t("t-please-enter-email")' required>
                                            <div class="invalid-feedback">
                                              {{ $t("t-please-enter-email") }}
                                            </div>
                                        </div>
                                      <div class="mb-3">
                                        <label for="phone_number" class="form-label">{{ $t("t-contact-phone-number") }}
                                          <span class="text-danger">*</span>
                                        </label>

                                        <input type="text" class="form-control" id="phone_number"
                                               v-model="form.phone_number"
                                               :placeholder='$t("t-please-enter-phone-number")' required>
                                        <div class="invalid-feedback">
                                          {{ $t("t-please-enter-phone-number") }}
                                        </div>
                                      </div>
                                        <div class="mb-3">
                                          <label for="username" class="form-label">{{ $t("t-username") }}
                                            <span class="text-danger">*</span></label>
                                          <input type="text" class="form-control" id="username"
                                                 v-model="form.username"
                                                 :placeholder='$t("t-please-enter-username")' required>
                                          <div class="invalid-feedback">
                                            {{ $t("t-please-enter-username") }}
                                          </div>
                                        </div>
                                        <div class="mb-3">
                                          <label for="username" class="form-label">
                                            {{ $t("t-password") }}
                                            <span class="text-danger">*</span>
                                          </label>
                                          <input type="text" class="form-control" id="password"
                                                 v-model="form.password"
                                                 minlength="8"
                                                 :placeholder='$t("t-please-enter-password")' required>
                                          <div class="invalid-feedback">
                                            {{ $t("t-please-enter-password") }}
                                          </div>
                                        </div>

                                        <div class="mb-3">
                                          <label for="referral_code" class="form-label">{{ $t("t-profile-referral-code") }}</label>
                                          <input type="text" class="form-control" id="referral_code"
                                                 v-model="form.referral_code"
                                                 :disabled="disabled_referral_input"
                                                 :placeholder='$t("t-please-enter-referral-code")'>
                                          <div class="invalid-feedback">
                                            {{ $t("t-please-enter-referral-code") }}
                                          </div>
                                        </div>

                                        <div class="mb-4">
                                            <p class="mb-0 fs-12 text-muted fst-italic">
                                              {{ $t("t-registering-approves-message") }}
                                              <a href="https://jump2pro.com/terms" target="_blank"
                                                    class="text-primary text-decoration-underline fst-italic fw-medium">
                                                {{ $t("t-terms-of-use") }}
                                              </a>
                                              {{ $t("t-damask-trading") }}
                                            </p>
                                        </div>
                                        <div class="mt-4">
                                            <button class="btn btn-success w-100" type="submit">{{ $t("t-sign-up") }}</button>
                                        </div>

                                        <div class="mt-4 text-center">
                                          <div class="signin-other-title"><h5 class="fs-13 mb-4 title">{{ $t("t-sign-up-with") }}</h5></div>
                                          <div>
                                            <button class="btn btn-danger btn-md btn-label" @click="google" type="button">
                                              <div class="btn-content">
                                                <i class="ri-google-fill label-icon align-middle fs-16 me-2"></i>
                                                Google
                                              </div>
                                            </button>
                                          </div>
                                      </div>
                                    </form>

                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->

                      <div class="mt-4 text-center">
                        <p class="mb-0">{{ $t("t-already-have-account") }}
                          <router-link to="/login" class="fw-semibold text-primary text-decoration-underline">
                            {{ $t("t-sign-in") }}
                          </router-link>
                        </p>
                      </div>


                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                          <p class="mb-0 text-muted">&copy; {{new Date().getFullYear()}} Jump2Pro.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>