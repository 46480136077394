<script>
import Layout from "./../../../layouts/main";
import appConfig from "../../../../app.config";

export default {
  page: {
    meta: [{ name: "Bitget integration", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
      <div class="row">
            <div class="col-lg-12">
                <!-- end card -->

                <div class="row justify-content-evenly">

                  <!-- Base Example -->
                  <div class="accordion" id="default-accordion-example">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Шаг 1. Регистрация на бирже
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Биржа может вносить свои визуальные изменения.
                            Внимательно читайте все подсказки на бирже!
                          </div>
                          <p>Пожалуйста, перейдите по ссылке для того, что бы начать регистрация на Bitget <a href="https://www.bitget.com/ru/referral/register?from=referral&clacCode=8VQDFRMK" target="_blank">https://www.bitget.com</a>.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Шаг 2. Пополнить счет на бирже
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Для трейдинга Вам понадобится <strong>USDC</strong>.
                          </div>
                          <p>
                            Для того, чтобы наша система могда совершать сделки, на Вашем счету должны быть USDC.
                            <br>
                            У Вас есть 2 варианта:
                          </p>
                          <ol>
                            <li>Перевести активы (USDC) с другой биржи или кошелька перейдя по <a href="https://www.bitget.com/ru/asset/recharge?coinId=2" target="_blank">ссылке</a>;</li>
                            <li>Купить нужный актив (USDC) за фиатные деньги перейдя по <a href="https://www.bitget.com/ru/buy-sell-crypto" target="_blank">ссылке</a>.</li>
                          </ol>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Выполняя определенные шаги, следуйте инструкции на Bitget!
                          </div>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Обратите внимание на то, что Ваши активы должны храниться на том счете,
                            с которым работает конктретный бот нашей системы.
                            Для спотового бота это спот счет, а для фьючерсного бота - фьючерс счет.
                          </div>
                          <p>Если все было сделано корректно, Вы увидите свои активы, перейдя по этой <a href="https://www.bitget.com/ru/asset">ссылке</a>.</p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading4">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                          Шаг 3. Создать API ключ
                        </button>
                      </h2>
                      <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-warning" role="alert">
                            <i class="las la-info-circle"></i>
                            Мы настоятельно рекомендуем Вам включить двухфакторную авторизацию!
                          </div>
                          <p>Для создания API ключа, перейдите по указанной <a href="https://www.bitget.com/ru/account/newapi" target="_blank">ссылке</a>.</p>

                          <img src="/Instruction/Bitget/api-keys.png" class="img-fluid border-dotted" />
                          <br><br>
                          <p>Нажмите кнопку «Create API Key». Вы увидите всплывающее окно, где нужно выбрать «System-generated API key». </p>
                          <img src="/Instruction/Bitget/select-api-key.png" class="img-fluid border-dotted col-md-6"/>
                          <br><br>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Для интеграции нам необходимы <strong>права на чтение</strong>.
                          </div>
                          <p>Далее заполняете форму и отмечаете галочками следущие поля:</p>
                          <img src="/Instruction/Bitget/create-api-key-1.png" class="img-fluid border-dotted col-md-6"/>
                          <br>
                          <br>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Для спотового бота отметьте <strong>спот и маржа</strong>.

                          </div>

                          <img src="/Instruction/Bitget/create-api-key-2.png" class="img-fluid border-dotted col-md-6"/>
                          <br><br>

                          <div class="alert alert-warning alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Для безопастности, убедитесь, что Вы добавили IP-адрес нашего сервера <strong>52.28.48.36</strong>.
                          </div>

                          <div class="alert alert-warning alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Пожалуйста, сохраните сгенерированнные ключи в надежном месте.
                            Они понадобятся Вам позже. Из соображений безопасности Вы не можете вернуться и
                            просмотреть его снова. Если Вы потеряете его,
                            Вам нужно будет сделать все шаги еще раз!
                          </div>

                          <div class="alert alert-danger alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                            <i class="ri-error-warning-line label-icon"></i>
                            <strong>Не устанавливайте</strong> флажки <strong>Перевод</strong> и <strong>Вывод</strong> в разделе Кошелеш!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading5">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                          Шаг 4. Интеграция с нашей системой
                        </button>
                      </h2>
                      <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>Перейдите по указанной <a href="/api-keys" target="_blank">ссылке</a> для того что бы добавить API ключ в нашу систему.</p>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Чтобы заполнить все обязательные поля, используйте данные из предыдущего шага.
                          </div>
                          <img src="/Instruction/Bitget/j2p-add-api-key.png" class="img-fluid border-dotted col-md-6"/>
                          <br><br>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading6">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                          Шаг 5. Создайте бота в нашей системе
                        </button>
                      </h2>
                      <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Внимательно ознакомьтесь с информацией на странице создания бота!
                          </div>
                          <p>Для создания торгового бота, перейдите по указанной <a href="/bots" target="_blank">ссылке</a> </p>

                          <img src="/Instruction/Bitget/j2p-create-bot.png" class="img-fluid border-dotted col-md-6" />
                          <br>
                          <br>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Сумма вашего депозита влияет на количество активных сделок и сумму сделок.
                          </div>
                          <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                            <i class="las la-info-circle label-icon"></i>
                            Мы рекомендуем иметь как можно больше активных сделок.
                          </div>
                          <p>Убедитесь, что <a href="/bots" target="_blank">Ваши боты</a> активированы.</p>
                          <img src="/Instruction/Bitget/bots-list.png" class="img-fluid border-dotted col-md-7" />
                        </div>
                  </div>
                </div>
            </div><!--end col-->.
        </div><!--end row-->
        </div><!--end row-->
        </div><!--end row-->
    </Layout>
</template>
